import Icon from "@/components/global/icon/Icon";
import { CardWithTitle } from "../case_details/components/cards/DetailsCards";
import OtherVitalDetails from "./components/otherVitals/otherVitals";
import PatientMonitoring from "./components/patientMonitoring/patientMonitoring";
import { Card } from "@/components/ui/card";
import { memo, useEffect, useRef, useState } from "react";
import useEvents from "@/api/hooks/useEvents";
import useOrders from "@/api/hooks/useOrder";
import { convertMinutesToTimeFormat } from "@/utils/timeUtil";
import { IconLoader } from "@tabler/icons-react";
import { useRedPush } from "@/network-hooks/red-push/redPush";
import axios from "axios";
import { fetchFleetLocationPing, setEntityIdKeyOnRedis } from "@/api/services/order.service";


const STATUS_OBJ = {
    'dispatched': {
        title: 'On the way to pick-up',
        color: 'text-[#7F56D9]',
        bgColor: 'bg-[#7F56D91A]'
      },
      'pickedup': {
        title: 'On the way to drop-off',
        color: 'text-[#DD2590]',
        bgColor: 'bg-[#DD25901A]'
      },
      "completed" : {
        title: 'Completed',
        color: 'text-[#099a0f]',
        bgColor: 'bg-[#099a0f1A]'
      }
}



const AmbulanceETACard = ({trackingDetail, status, id, city, pilotId, fleetId, vehicleNo, tripStatus, waypoints, createdAt, pusher}) => {

    const pilotTrackingPusher = useRedPush('tracking_link_lp_'+id);
    // const fleetTrackingPusher = useRedPush('tracking_link_lp_fleet_'+id);

    const [currentLocation, setCurrentLocation] = useState(trackingDetail?.currLocation);
    const [destination, setDestination] = useState<any>();
    const locationInterval = useRef<NodeJS.Timeout>(null);
    const redisInterval = useRef<NodeJS.Timeout>(null);
    const [lastUpdated, setLastUpdated] = useState(Number(createdAt));
    const [currentEta, setCurrentEta] = useState(1);
    let position = trackingDetail?.initialEta ?  `${95 - Math.floor((currentEta / trackingDetail?.initialEta)*100)}%` : '-5%';

    if(Math.floor((currentEta / trackingDetail?.initialEta)*100) > 100) {
        position = '-5%';
    }

    if(status === 'completed') {
        position = '95%';
    }

    const getCurrentEta = (eta: number) => {
    return convertMinutesToTimeFormat(Math.ceil(eta/60));
    }

    function startAPIPolling() {
        clearInterval(locationInterval.current);
        locationInterval.current = setInterval(() => {
            getFleetLocationPing(vehicleNo);
        }, 10000);
    }

    const getEtaFromOlaMaps = async () => {
        try {
            const origin = currentLocation;
            const dest = destination;
            if(!origin || !dest) {
                return;
            }
          const response = await axios.post(`https://api.olamaps.io/routing/v1/directions?origin=${origin?.lat}%2C%20${origin?.lng}&destination=${dest?.lat}%2C%20${dest?.long}&mode=driving&alternatives=false&steps=true&overview=full&language=en&traffic_metadata=false&api_key=uBppCGqFXO712SF5uRBoYp9103JgtnZpFDCpA85y`)
          console.log('OLA MAPS RESP --->', response.data);
          
          if(response?.data?.routes?.[0]?.legs?.[0]?.distance) {
            console.log("ETA: ", response?.data?.routes?.[0]?.legs?.[0]?.duration);
            setCurrentEta(response?.data?.routes?.[0]?.legs?.[0]?.duration);
          }
    
        }
        catch (err) {
          console.log('OLA ERROR ---> ', err);
        }
    }

    const setKey = async (entityId: string, expiry: number) => {
        try {
          const response = await setEntityIdKeyOnRedis(entityId, expiry);
          console.log(response); // Logs success message
        } catch (error: any) {
          console.error('Error setting key:', error.response?.data?.error || error.message);
        }
      };
      
      
      const startRedisKeyPolling = () => {
        // const pilotId = orderDetails?.assignmentDetails?.pilotDetails?.pilotId;
        if(redisInterval.current) {
          clearInterval(redisInterval.current)
        }
        setKey(pilotId, 80);
        redisInterval.current = setInterval(()=> {
          setKey(pilotId, 80);
        }, 60*1000);
      }


    const getFleetLocationPing = async (vehicleNo: string) => {
        try {
          const response = await fetchFleetLocationPing(vehicleNo);
          const oldTimestamp = new Date(lastUpdated).getTime();
          const newTimestamp = new Date(response?.data?.lastUpdatedAt).getTime();

          if(!currentLocation) {
            setCurrentLocation({
                lat: Number(response?.data.latitude),
                lng: Number(response?.data.longitude)
            });
        }

          if(newTimestamp - oldTimestamp > 0) {
            setLastUpdated(response?.data?.lastUpdatedAt);
            if(response?.data.latitude && Math.abs(currentLocation.lat - Number(response?.data.latitude)) > 0.00001 || Math.abs(currentLocation.lng - Number(response?.data.longitude))> 0.00001){
                setCurrentLocation({
                    lat: Number(response?.data.latitude),
                    lng: Number(response?.data.longitude)
                });
            }
          }
        }
        catch (er) {}
      }

    const handleLatestCoords = (data:any) => {
        if(data.message && (data.message.entityId === pilotId || data.message.entityId === fleetId)) {
            const oldTimestamp = new Date(lastUpdated).getTime();
            const newTimestamp = new Date(data.message.timestamp).getTime();

            if(!currentLocation) {
                setCurrentLocation({
                    lat: Number(data.message.lat),
                    lng: Number(data.message.lng)
                });
            }

            if(newTimestamp - oldTimestamp > 0) {
                setLastUpdated(data.message.timestamp);
                if((data.message.lat && Math.abs(currentLocation.lat - Number(data.message.lat)) > 0.0001) || (data.message.lng && Math.abs(currentLocation.lng - Number(data.message.lng)) > 0.00001)) {
                    setCurrentLocation({
                        lat: Number(data.message.lat),
                        lng: Number(data.message.lng)
                    });
                }
            }
        }
    };

    useEffect(() => {
        pusher.subscribe( 'PILOT_LP', [`lp.${city}.${pilotId}`], handleLatestCoords);
        // fleetTrackingPusher.subscribe([`lp.${city}.${fleetId}`], handleLatestCoords)
        startRedisKeyPolling();
        startAPIPolling();

        return () => {
            pusher.unsubscribe('PILOT_LP', [`lp.${city}.${pilotId}`]);
            clearInterval(locationInterval.current)
            clearInterval(redisInterval.current);
        }

    }, []);

    useEffect(() => {
        if(tripStatus === 'pickedup') {
            setDestination(waypoints[1].geo)
        }else if(tripStatus === 'dispatched') {
            setDestination(waypoints[0].geo)
        }
    }, [tripStatus])

    useEffect(() => {
        getEtaFromOlaMaps();
    }, [currentLocation])


    return (
        <Card>
                <div className="p-3 pr-1 flex  justify-between">
                    <div className="flex flex-col w-[28%]">
                        <div className={`text-[#5B6169] text-sm`}>{'Status'}</div>
                        <div className={`${STATUS_OBJ?.[status]?.color} ${STATUS_OBJ?.[status]?.bgColor} text-xs rounded-2xl pl-2 py-0.5`}>{STATUS_OBJ?.[status]?.title}</div>
                    </div>
                    <div className="flex flex-col w-[40%] items-center justify-center">
                    <div className='flex h-px w-full items-center bg-black'>
              <div className='h-2 w-2 bg-black'></div>
              <div className='relative left-[96%] h-2 w-2 rounded bg-black'></div>
              <div className='relative left-[20%] animate-pulse' style={{left: position}}>
                <img src='/images/amb-round.svg' />
              </div>
            </div>
                    </div>
                    <div className="flex flex-col items-center">
                        <div className="text-[#5B6169] text-sm">{status ==='dispatched' ? 'Time to pick-up' : 'Time to drop-off'}</div>
                        <div className="text-[#161B1F] text-lg font-bold">{currentEta ? getCurrentEta(currentEta) : <IconLoader className='animate-spin' />}</div>
                    </div>
                </div>
            </Card>
    )
}



const _CaseVitalsPage = ({orderId, pusher}: any) => {
    const alertSound = new Audio('assets/audio/Siren.mp3');
    const alertAnimationClasses = 'relative animate-borderColorPulse';
    const { eventsState, removeAlertForOrderId } = useEvents();
    const { ongoingCases} =  useOrders();
    const [alertGlow, setAlertGlow] = useState(false);
    const currentCase = ongoingCases.filter((entry)=> {
        return entry?.caseId === orderId
    })?.[0] ?? {};

    const vitalAlertsForOrderId = eventsState?.[orderId]?.alerts?.filter((entry: any)=> {
        return entry?.eventType === 'VITAL';
    }) ?? [];
    // const vitalAlerts = alertsForOrderId?.filter((entry: any)=> {
    //     return entry?.
    // })

    // if(alertsForOrderId.length === 10 ) {
    //     alertSound.play();
    // }

    // setTimeout(()=> {
    //     removeAlertForOrderId(orderId, alertsForOrderId?.filter((entry:any) => {
    //         return entry?.eventType !== 'VITAL';
    //     }))
    // }, 1000)

    useEffect(()=> {
        if(vitalAlertsForOrderId?.length > 0) {
        //   setAlertGlow(true);
          removeAlertForOrderId(orderId, eventsState?.[orderId]?.alerts?.filter((entry: any) => {
            return entry?.eventType !== 'VITAL';
          }))
        }
        if(vitalAlertsForOrderId?.length ===0 && alertGlow) {
          setTimeout(()=> {
            setAlertGlow(false);
          }, 5000);
        }
      }, [vitalAlertsForOrderId]);



    
    return (
        <div className="flex px-2 py-2 flex-col" >
            <AmbulanceETACard 
                trackingDetail ={currentCase?.tripTracking} 
                status={currentCase?.overallStatus} 
                id={currentCase?.caseId} city={currentCase?.city}  
                pilotId={currentCase?.assignmentDetails?.pilotId} 
                fleetId={currentCase?.assignmentDetails?.fleetId} 
                vehicleNo={currentCase?.assignmentDetails?.fleetDetails?.ambId}
                tripStatus={currentCase?.overallStatus}
                waypoints={currentCase?.waypoints}
                createdAt={currentCase?.createdAt}
                pusher={pusher}
            />
            <CardWithTitle
                alertClasses={`${alertGlow && alertAnimationClasses}`}
                title={<div className="flex items-center justify-center">
                    <Icon icon='uil:monitor-heart-rate' color='#161B1F'/>
                    <div className="ml-3 text-sm font-semibold"> {"Patient Monitor"} </div>
                    </div>}
                className={'text-center w-full mt-3'}
                styles={{
                    backgroundColor: '#F7F7F7',
                }}
            >
                <div className="h-[40vh] min-h-[300px] w-full">
                    {<PatientMonitoring orderId ={orderId} pusher={pusher} />}
                </div>
            </ CardWithTitle>
            <div>
                {/* <OtherVitalDetails /> */}
            </div>
        </div>
    )
}

const CaseVitalsPage = memo(_CaseVitalsPage);

export default CaseVitalsPage;